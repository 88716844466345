import { DatePicker, Form, InputNumber } from "antd";
import FormFooter from "./FormFooter";
import { useEffect } from "react";
import moment from "moment";

interface RenewalFormProps {
  form: any;
  onFormSubmit: () => void;
  onFormReset: () => void;
}

const RenewalForm: React.FC<RenewalFormProps> = ({ form, onFormReset, onFormSubmit }) => {
  const updateStartDate = () => {
    let endDate = form.getFieldValue('policy_end_date')
    if (endDate) {
      form.setFieldsValue({ policy_start_date: moment(endDate).subtract(366, 'd') })
    }
  };

  return <>
    <h2>Fill in the form below</h2>
    <Form form={form}>
      <Form.Item
        label="Policy End Date"
        name="policy_end_date"
        rules={[{ required: true, message: 'Please enter the policy end date' }]}
      >
        <DatePicker onChange={updateStartDate} format={"DD-MMM-YYYY"} style={{ width: "100%" }} placeholder='Select Date' />
      </Form.Item>
      <Form.Item
        label="Policy Start Date"
        name="policy_start_date"
        shouldUpdate={(prevValues, curValues) => prevValues.policy_end_date !== curValues.policy_end_date}
        rules={[{ required: true, message: 'Please enter the policy start date' }]}
      >
        <DatePicker format={"DD-MMM-YYYY"} style={{ width: "100%" }} placeholder='Select Date' />
      </Form.Item>
      <Form.Item
        label="Claim MIS Generated Date"
        name="claims_mis_generated_date"
        rules={[{ required: true, message: 'Please enter the Claim MIS generated date' }]}
      >
        <DatePicker format={"DD-MMM-YYYY"} style={{ width: "100%" }} placeholder='Select Date' />
      </Form.Item>
      <Form.Item
        label="Lives at start of policy"
        name="lives_at_start"
        rules={[{ required: true, message: 'Please enter the number of lives at start of policy' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder='Enter number of lives' />
      </Form.Item>
      <Form.Item
        label="Lives for renewal"
        name="lives_at_renewal"
        rules={[{ required: true, message: 'Please enter the number of lives at renewal' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder='Enter number of lives' />
      </Form.Item>
      <Form.Item
        label="Claims Paid"
        name="claims_paid"
        rules={[{ required: true, message: 'Please enter the amount of claims paid' }]}
      >
        <InputNumber addonBefore="₹" formatter={(value: any) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')} style={{ width: "100%" }} placeholder='Enter amount' />
      </Form.Item>
      <Form.Item
        label="Outstanding Claims"
        name="outstanding_claims"
        rules={[{ required: true, message: 'Please enter the amount of outstanding claims' }]}
      >
        <InputNumber formatter={(value: any) => (value ? parseInt(value)?.toLocaleString('en-IN') : '')} addonBefore="₹" style={{ width: "100%" }} placeholder='Enter amount' />
      </Form.Item>
      <Form.Item
        label="Buffer cost"
        name="buffer_cost"
      >
        <InputNumber addonBefore="%" style={{ width: "100%" }} placeholder='Enter percentage' />
      </Form.Item>
      <Form.Item
        label="Inflation cost"
        name="inflation_cost"
      >
        <InputNumber addonBefore="%" style={{ width: "100%" }} placeholder='Enter percentage' />
      </Form.Item>
      <Form.Item
        label="Management cost"
        name="management_cost"
      >
        <InputNumber addonBefore="%" style={{ width: "100%" }} placeholder='Enter percentage' />
      </Form.Item>
      <FormFooter onFormReset={onFormReset} onFormSubmit={onFormSubmit} />
    </Form></>
};

export default RenewalForm;
