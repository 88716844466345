export const binary_options = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
]

export const dental_cover_options = [
  {
    label: "₹3K",
    value: "3k"
  },
  {
    label: "₹5K",
    value: "5k"
  },
  {
    label: "No",
    value: false
  }
]

export const gpa_gtli_options = [
  {
    label: "₹5L",
    value: "5L"
  },
  {
    label: "₹10L",
    value: "10L"
  },
  {
    label: "₹20L",
    value: "20L"
  },
  {
    label: "No",
    value: false
  }
]

export const plan_type_options = [
  {
    label: "Silver (Normal - 1% of SI; ICU - 2% of SI)",
    value: "silver"
  },
  {
    label: "Gold (Normal - 2% of SI; ICU - 4% of SI)",
    value: "gold"
  },
  {
    label: "Platinum (Normal & ICU - No limit)",
    value: "platinum"
  }
]

export const city_options = [
  {
    label: "Ahmedabad",
    value: "ahmedabad"
  },
  {
    label: "Bangalore",
    value: "bangalore"
  },
  {
    label: "Chennai",
    value: "chennai"
  },
  {
    label: "Delhi",
    value: "delhi"
  },
  {
    label: "Hyderabad",
    value: "hyderabad"
  },
  {
    label: "Kolkata",
    value: "kolkata"
  },
  {
    label: "Mumbai",
    value: "mumbai"
  },
  {
    label: "Pune",
    value: "pune"
  },
  {
    label: "Secunderabad",
    value: "secunderabad"
  },
  {
    label: "Surat",
    value: "surat"
  },
  {
    label: "Vadodara",
    value: "vadodara"
  },
  {
    label: "Rest of India",
    value: "rest_of_india"
  }
]

export const doctor_teleconsultations_options = [
  {
    label: "Plan E1 (GP + 10 Specialists)",
    value: "e1"
  },
  {
    label: "Plan E2 (GP + 20 Specialists)",
    value: "e2"
  },
  {
    label: "No",
    value: false
  },
]

export const digit_age_range_options = [
  {
    label: '0-25 years',
    value: 25
  },
  {
    label: '25-35 years',
    value: 35
  },
  {
    label: '35-45 years',
    value: 45
  },
  {
    label: '45-55 years',
    value: 55
  },
  {
    label: '55-65 years',
    value: 65
  },
]