import { Alert, Col, Row } from "antd";

interface RenewalDisplayProps {
  quote: any;
  showAlert: any;
}

const RenewalDisplay: React.FC<RenewalDisplayProps> = ({
  quote,
  showAlert,
}) => {
  const totalPremium = Math.round(
    quote?.amount?.expected_claims_for_next_year /
      (1 -
        quote?.amount?.buffer -
        quote?.amount?.management_costs -
        quote?.amount?.inflation)
  );
  return (
    <>
      <Row gutter={[24, 48]} align={"top"}>
        <Col span={8}>
          <div className="category-heading">
            <h3>Annualized Claims</h3>
            <h4 style={{ color: "green" }}>For this year</h4>
          </div>
          <div style={{ fontSize: "20px" }}>
            {`₹${Math.round(quote?.amount?.annualized_claims)?.toLocaleString(
              "en-IN"
            )}`}
          </div>
        </Col>
        <Col span={8}>
          <div className="category-heading">
            <h3>Expected Claims</h3>
            <h4 style={{ color: "green" }}>For next year</h4>
          </div>
          <div style={{ fontSize: "20px" }}>
            {showAlert?.showAlert ? (
              <span style={{ color: "red", fontStyle: "italic" }}>
                Cannot be predicted
              </span>
            ) : (
              `₹${Math.round(
                quote?.amount?.expected_claims_for_next_year
              ).toLocaleString("en-IN")}`
            )}
          </div>
        </Col>
        <Col span={8}>
          <div className="category-heading">
            <h3>Claims per life</h3>
          </div>
          <div style={{ fontSize: "20px" }}>
            {`₹${Math.round(
              quote?.amount?.expected_claims_for_next_year / quote?.lives?.lives_for_renewal
            ).toLocaleString("en-IN")}`}
          </div>
        </Col>
        <Col span={8} />
        <Col span={8}>
          <div className="category-heading">
            <h3>Annual Indicative Premium</h3>
          </div>
          <div style={{ fontSize: "20px" }}>
            {showAlert?.showAlert
              ? "Refer to UW"
              : `₹${totalPremium.toLocaleString("en-IN")}`}
          </div>
        </Col>
        <Col span={8}>
          <div className="category-heading">
            <h3>Premium per life</h3>
          </div>
          <div style={{ fontSize: "20px" }}>
            {showAlert?.showAlert
              ? "NA"
              : `₹${Math.round(
                  totalPremium / quote?.lives?.lives_for_renewal
                ).toLocaleString("en-IN")}`}
          </div>
        </Col>
      </Row>
      {showAlert?.showAlert && (
        <Alert
          style={{ marginTop: "64px" }}
          type="warning"
          message={showAlert?.reason}
        />
      )}
    </>
  );
};

export default RenewalDisplay;
