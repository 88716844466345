import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

interface FormFooterProps {
  onFormSubmit: () => void;
  onFormReset: () => void;
}


const FormFooter: React.FC<FormFooterProps> = ({onFormReset, onFormSubmit}) => {
  const navigate = useNavigate();
  return <Row gutter={16} justify={'center'} style={{ marginTop: "48px" }}>
  <Col>
    <Button type="primary" onClick={() => navigate('..')}>
      Go Back
    </Button>
  </Col>
  <Col>
    <Button type="primary" onClick={onFormReset}>
      Reset
    </Button>
  </Col>
  <Col>
    <Button type="primary" htmlType="submit" onClick={onFormSubmit} style={{ marginBottom: "48px" }}>
      Generate Quote
    </Button>
  </Col>
</Row>
};

export default FormFooter;
