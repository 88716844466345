import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import CardButton from "../components/CardButton";
import Logout from "../components/Logout";

interface Props {
  logout: () => void;
}

const LandingPage: React.FC<Props> = ({ logout }) => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <Logout logout={logout} />
      <Row justify={"center"}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={0} xs={0}>
          <CardButton onClick={() => navigate('/fresh')} color="#f2663fe6" height="100vh">Fresh</CardButton>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={0} xs={0}>
          <CardButton onClick={() => navigate('/renewal')} color="#78c377e6" height="100vh">Renewal</CardButton>
        </Col>
        <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
          <CardButton onClick={() => navigate('/fresh')} color="#f2663fe6" height="50vh">Fresh</CardButton>
        </Col>
        <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
          <CardButton onClick={() => navigate('/renewal')} color="#78c377e6" height="50vh">Renewal</CardButton>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
