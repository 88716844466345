import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { digit_age_range_options } from "../db/options";
import FormFooter from "./FormFooter";

interface Props {
  form: FormInstance;
  onFormSubmit: VoidFunction;
  onFormReset: VoidFunction;
}

const DigitForm: React.FC<Props> = ({ form, onFormReset, onFormSubmit }) => {
  return (
    <>
      <h2>Fill in the form below</h2>
      <Form form={form}>
        <Form.Item label="GST Number" name="gstNumber">
          <Input />
        </Form.Item>
        <Form.Item label="PAN Number" name="panNumber">
          <Input />
        </Form.Item>
        <Form.Item label="Master Policy Number" name="masterPolicyNumber" rules={[
          {required: true, message: 'Please enter master policy number'}
        ]}>
          <Input />
        </Form.Item>
        <Form.List name="quotePremiumEntity">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "ageRange"]}
                    rules={[{ required: true, message: "Select an age range" }]}
                  >
                    <Select
                      placeholder="Age range"
                      options={digit_age_range_options}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "sumInsured"]}
                    rules={[{ required: true, message: "Enter Sum Insured" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      placeholder="Sum Insured"
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "numberOfEmployee"]}
                    rules={[
                      { required: true, message: "Enter number of employees" },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      placeholder="Number of Employees"
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Age Group
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <FormFooter onFormReset={onFormReset} onFormSubmit={onFormSubmit} />
      </Form>
    </>
  );
};

export default DigitForm;
