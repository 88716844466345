import { Alert, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import RenewalForm from "../components/RenewalForm";
import RenewalDisplay from "../components/RenewalDisplay";
import { costs, ibnr_percentage } from "../db/quotes";
import moment from "moment";
import Logout from "../components/Logout";

interface Props {
  logout: () => void;
}

interface RenewalData {
  days: {
    policy_completed_days: number;
    balance_days: number;
  },
  lives: {
    avg_lives_prev_year: number;
    lives_for_renewal: number;
    change_percentage: number;
  },
  amount: {
    total_claims: number;
    incurred_but_unreported_claims: number;
    total_claims_as_on_date: number;
    claims_for_remainder_period: number;
    annualized_claims: number;
    claims_increase_due_to_increase_in_no_of_lives: number;
    expected_claims_for_next_year: number;
    buffer: number;
    inflation: number;
    management_costs: number;
  }
}

interface AlertProps {
  showAlert: boolean;
  reason: string;
}

const RenewalPage: React.FC<Props> = ({ logout }) => {
  const [form] = Form.useForm(),
    [data, setData] = useState<null | RenewalData>(null),
    [showAlert, setShowAlert] = useState<AlertProps | null>(null),
    onFormSubmit = () => {
      form.validateFields()
        .then((values) => {
          setShowAlert(null)
          let balance_days = values?.policy_end_date.diff(values?.claims_mis_generated_date, 'days') + 1
          let policy_completed_days = values?.policy_end_date.diff(values?.policy_start_date, 'days') - balance_days + 1
          let avg_lives_prev_year = (values?.lives_at_start + values?.lives_at_renewal) / 2
          let change_percentage = (values?.lives_at_renewal / avg_lives_prev_year) - 1
          let total_claims = values?.claims_paid + values?.outstanding_claims
          let incurred_but_unreported_claims = total_claims * ibnr_percentage
          let total_claims_as_on_date = total_claims + incurred_but_unreported_claims
          let claims_for_remainder_period = total_claims_as_on_date * balance_days / policy_completed_days
          let annualized_claims = total_claims_as_on_date + claims_for_remainder_period
          let claims_increase_due_to_increase_in_no_of_lives = annualized_claims * change_percentage
          let expected_claims_for_next_year = annualized_claims + claims_increase_due_to_increase_in_no_of_lives


          if ((expected_claims_for_next_year / values?.lives_at_renewal) < 1000) {
            setShowAlert({
              showAlert: true,
              reason: "Expected claims per life is less than 1000"
            })
          }
          if (values?.lives_at_renewal < 100) {
            setShowAlert({
              showAlert: true,
              reason: "Lives at renewal is less than 100"
            })
          }
          if (change_percentage * 100 > 100) {
            setShowAlert({
              showAlert: true,
              reason: "Change in lives is more than 100%"
            })
          }
          if (balance_days > 60) {
            setShowAlert({
              showAlert: true,
              reason: "Difference between Claim MIS generated date and Policy end date is more than 60 days"
            })
          }
          if (moment() > values?.policy_end_date) {
            setShowAlert({
              showAlert: true,
              reason: "Policy has expired"
            })
          }

          setData({
            days: {
              policy_completed_days: policy_completed_days,
              balance_days: balance_days
            },
            lives: {
              avg_lives_prev_year: avg_lives_prev_year,
              lives_for_renewal: values?.lives_at_renewal,
              change_percentage: change_percentage
            },
            amount: {
              total_claims: total_claims,
              incurred_but_unreported_claims: incurred_but_unreported_claims,
              total_claims_as_on_date: total_claims_as_on_date,
              claims_for_remainder_period: claims_for_remainder_period,
              annualized_claims: annualized_claims,
              claims_increase_due_to_increase_in_no_of_lives: claims_increase_due_to_increase_in_no_of_lives,
              expected_claims_for_next_year: expected_claims_for_next_year,
              buffer: values?.buffer_cost / 100 || costs?.buffer,
              inflation: values?.inflation_cost / 100 || costs?.inflation,
              management_costs: values?.management_cost / 100 || costs?.management
            }
          })
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    onFormReset = () => {
      form.resetFields();
      setData(null);
    };

  useEffect(() => {
    form.setFieldsValue({
      buffer_cost: costs?.buffer * 100,
      inflation_cost: costs?.inflation * 100,
      management_cost: costs?.management * 100,
    })
  }, [])

  return <div className="layout">
    <div className="container">
      <Logout logout={logout} />
      <Row justify={"center"} style={{ padding: "24px 0 24px 0" }}>
        <h1>Generate Renewal Quote in under a minute!</h1>
      </Row>
      <Row gutter={64} align="top">
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <RenewalForm form={form} onFormSubmit={onFormSubmit} onFormReset={onFormReset} />
        </Col>
        {data && <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} style={{ marginBottom: "24px" }}>
          <RenewalDisplay quote={data} showAlert={showAlert} />
        </Col>}
        {!data && <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Alert type="info" message="Quote will be shown here" style={{ textAlign: "center", padding: "64px 0", marginBottom: "24px" }} />
        </Col>}
      </Row>
    </div>
  </div>
};

export default RenewalPage;
