import { Alert, Col, Form, Row } from "antd";
import QuoteDisplay from "../components/QuoteDisplay";
import QuoteForm from "../components/QuoteForm";
import { useState } from "react";
import { base_city_discount, base_type_discount, city_tier_relationship, ghi_quotes, ghi_quotes_under_25, gpa_quotes, gtli_quotes, hnw_quotes, without_maternity, without_maternity_under_25 } from "../db/quotes";
import Logout from "../components/Logout";

interface Props {
  logout: () => void;
}

interface Quote {
  id: number;
  ghi_si: string;
  e: number;
  esc: number;
  escp: number;
}

interface AddOn {
  gpa?: number | null;
  gtli?: number | null;
  dental_cover?: number | null;
  doctor_teleconsultations?: number | null;
  gym_membership?: number | null;
  health_checkups?: number | null;
  emotional_wellness?: number | null;
}

const FreshPage: React.FC<Props> = ({ logout }) => {
  const [form] = Form.useForm(),
    [data, setData] = useState<null | Array<Quote>>(null),
    [ghiData, setGhiData] = useState<null | Array<Quote>>([]),
    [addOn, setAddOn] = useState<AddOn | null>(null),
    onFormSubmit = () => {
      form.validateFields()
        .then((values) => {
          let addon = 0;
          let discount = 0;
          let addOnDetails = {} as any;
          if (values?.gym_membership) {
            addon += hnw_quotes?.gym_membership;
            addOnDetails.gym_membership = hnw_quotes?.gym_membership.toLocaleString('en-IN');
          }
          if (values?.health_checkups) {
            addon += hnw_quotes?.health_checkups;
            addOnDetails.health_checkups = hnw_quotes?.health_checkups.toLocaleString('en-IN');
          }
          if (values?.emotional_wellness) {
            addon += hnw_quotes?.emotional_wellness;
            addOnDetails.emotional_wellness = hnw_quotes?.emotional_wellness.toLocaleString('en-IN');
          }
          if (values?.dental_cover) {
            addon += hnw_quotes?.dental_cover?.find((item) => item.plan === values?.dental_cover)?.premium || 0;
            addOnDetails.dental_cover = hnw_quotes?.dental_cover?.find((item) => item.plan === values?.dental_cover)?.premium.toLocaleString('en-IN') || 0;
          }
          if (values?.doctor_teleconsultations) {
            addon += hnw_quotes?.doctor_teleconsultation?.find((item) => item.plan === values?.doctor_teleconsultations)?.premium || 0;
            addOnDetails.doctor_teleconsultations = hnw_quotes?.doctor_teleconsultation?.find((item) => item.plan === values?.doctor_teleconsultations)?.premium.toLocaleString('en-IN') || 0;
          }
          if (values?.gpa) {
            addon += gpa_quotes?.find((item) => item.gpa_si === values?.gpa)?.premium || 0;
            addOnDetails.gpa = gpa_quotes?.find((item) => item.gpa_si === values?.gpa)?.premium.toLocaleString('en-IN') || 0;
          }
          if (values?.gtli) {
            addon += gtli_quotes?.find((item) => item.gtli_si === values?.gtli)?.premium || 0;
            addOnDetails.gtli = gtli_quotes?.find((item) => item.gtli_si === values?.gtli)?.premium.toLocaleString('en-IN') || 0;
          }
          if (values?.plan_type) {
            discount += base_type_discount?.find((item) => item.plan_type === values?.plan_type)?.discount || 0;
          }
          if (values?.city) {
            if (values?.employee_count > 25) discount += base_city_discount?.find((item) => item?.tier === city_tier_relationship?.find((item) => item?.cities?.includes(values?.city))?.tier)?.discount || 0;
          }
          let ghi_dataSource = [] as any;
          if (values?.employee_count > 25) {
            ghi_dataSource = ghi_quotes?.map((item) => {
              let row = item;
              if (!values?.maternity_cover) {
                row = {
                  ...row,
                  e: row.e + without_maternity['e'],
                  esc: row.esc + without_maternity['esc'],
                  escp: row.escp + without_maternity['escp'],
                }
              }
              return {
                ...row,
                e: Math.ceil((1 - discount) * (row.e)),
                esc: Math.ceil((1 - discount) * (row.esc)),
                escp: Math.ceil((1 - discount) * (row.escp)),
              }
            })
          } else {
            ghi_dataSource = ghi_quotes_under_25?.map((item) => {
              let row = item;
              if (!values?.maternity_cover) {
                row = {
                  ...row,
                  e: row.e + without_maternity_under_25['e'],
                  esc: row.esc + without_maternity_under_25['esc'],
                  escp: row.escp + without_maternity_under_25['escp'],
                }
              }
              return {
                ...row,
                e: item?.ghi_si === "10L" ? null : Math.ceil((1 - discount) * (row.e)),
                esc: item?.ghi_si === "10L" ? null : Math.ceil((1 - discount) * (row.esc)),
                escp: null,
              }
            })
          }
          setData(ghi_dataSource?.map((item: any) => {
            return {
              ...item,
              e: (item.e + addon) * values?.employee_count,
              esc: (item.esc + addon) * values?.employee_count,
              escp: (item.escp + addon) * values?.employee_count
            }
          }))
          setAddOn(addOnDetails)
          setGhiData(ghi_dataSource)
        })
        .catch((err) => {
          // console.error(err);
        });
    },
    onFormReset = () => {
      form.resetFields();
      setData(null);
    };

  return <div className="layout">
    <div className="container">
      <Logout logout={logout} />
      <Row justify={"center"} style={{ padding: "24px 0 24px 0" }}>
        <h1>Generate Fresh Quote in under a minute!</h1>
      </Row>
      <Row gutter={64} align="top">
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <QuoteForm form={form} onFormSubmit={onFormSubmit} onFormReset={onFormReset} />
        </Col>
        {data && ghiData && <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} style={{ marginBottom: "24px" }}>
          <QuoteDisplay quote={data} ghi_quote={ghiData} addOn={addOn} />
        </Col>}
        {!data && <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Alert type="info" message="Quote will be shown here" style={{ textAlign: "center", padding: "64px 0", marginBottom: "24px" }} />
        </Col>}
      </Row>
    </div>
  </div>
};

export default FreshPage;
