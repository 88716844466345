import { Button, Form, Input, Modal } from "antd";
import { accounts } from "../db/accounts";

interface Props {
  login: (token?: string) => void;
}

const Login: React.FC<Props> = ({ login }) => {
  const [form] = Form.useForm(),
    onFormSubmit = () => {
      form.validateFields()
        .then((values) => {
          if (accounts?.find((account) => account.email === values?.email)) {
            if (accounts?.find((account) => ( account.email === values?.email && account.password === values?.password))) {
              login(accounts?.find((account) => ( account.email === values?.email && account.password === values?.password))?.name)
            } else {
              Modal.error({
                title: 'Uh-Oh! Your Password is incorrect',
                content: 'Are you an imposter? If not, please check your password and try again or contact the bharatsure team',
              });
            }
          } else {
            Modal.error({
              title: 'We have not heard of that email/username before!',
              content: `Are you sure it's the right one? If not, please check your email/username and try again or contact the bharatsure team`,
            });
          }
        })
        .catch((error) => { });
    };

  return <div className="container">
    <div className="column">
      <div className="login" style={{ textAlign: "center" }}>
        <h1 >Bharatsure's Placement Portal</h1>
        <Form onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onFormSubmit();
          }
        }} form={form}>
          <Form.Item
            label="Email or Username"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            name="email"
            rules={[{ required: true, message: 'Please enter your email/username' }]}
          >
            <Input placeholder="Who is it 🤔?" />
          </Form.Item>
          <Form.Item
            label="Password"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password placeholder="Shhh! It's a secret 🤫" />
          </Form.Item>
          <Button type="primary" onClick={onFormSubmit} style={{ marginTop: '24px' }}>
            Login
          </Button>
        </Form>
      </div>
    </div>
  </div>;
};

export default Login;
