import { Form, InputNumber, Select } from 'antd';
import FormFooter from './FormFooter';
import { city_options, plan_type_options, binary_options, gpa_gtli_options, dental_cover_options, doctor_teleconsultations_options } from '../db/options';
import { useEffect, useState } from 'react';

interface QuoteFormProps {
  form: any;
  onFormSubmit: () => void;
  onFormReset: () => void;
}

const QuoteForm: React.FC<QuoteFormProps> = ({form, onFormSubmit, onFormReset}) => {
  const [disabled, setDisabled] = useState<boolean>(false),
  updatePlanType = () => {
    let employeeCount = form.getFieldValue("employee_count")
    if (employeeCount>25){
      setDisabled(false)
    } else {
      setDisabled(true)
      form.setFieldsValue({
        "plan_type": "gold"
      })
    }
  };

  return <>
    <h2>Fill in the form below</h2>
    <Form form={form}>
      <Form.Item
        label="City"
        name="city"
        rules={[{ required: true, message: 'Please select a city!' }]}
      >
        <Select placeholder="Select a city" options={city_options} />
      </Form.Item>
      <Form.Item
        label="Number of Employees"
        name="employee_count"
        rules={[{ required: true, message: 'Please enter the number of employees' }]}
      >
        <InputNumber onChange={updatePlanType} style={{width: "100%"}} min={1} placeholder='Enter number of employees' />
      </Form.Item>
      <Form.Item
        label="Plan Type"
        name="plan_type"
        rules={[{ required: true, message: 'Please select the plan for GHI policy' }]}
        shouldUpdate={(prevValues, curValues) => prevValues.employee_count !== curValues.employee_count}
      >
        <Select disabled={disabled} placeholder="Select a plan" options={plan_type_options} />
      </Form.Item>
      <Form.Item
        label="Maternity Cover"
        name="maternity_cover"
        rules={[{ required: true, message: 'Please select if you wish to opt in for the maternity cover!' }]}
      >
        <Select placeholder="Select the maternity cover" options={binary_options} />
      </Form.Item>
      <Form.Item
        label="GPA Cover"
        name="gpa"
      >
        <Select placeholder="Select a sum insured" options={gpa_gtli_options} />
      </Form.Item>
      <Form.Item
        label="GTLI Cover"
        name="gtli"
      >
        <Select placeholder="Select a sum insured" options={gpa_gtli_options} />
      </Form.Item>
      <Form.Item
        label="Doctor Teleconsultations"
        name="doctor_teleconsultations"
      >
        <Select placeholder="Select an option" options={doctor_teleconsultations_options} />
      </Form.Item>
      <Form.Item
        label="Health Check ups"
        name="health_checkups"
      >
        <Select placeholder="Select an option" options={binary_options} />
      </Form.Item>
      <Form.Item
        label="Gym Membership"
        name="gym_membership"
      >
        <Select placeholder="Select an option" options={binary_options} />
      </Form.Item>
      <Form.Item
        label="Emotional Wellness"
        name="emotional_wellness"
      >
        <Select placeholder="Select an option" options={binary_options} />
      </Form.Item>
      <Form.Item
        label="Dental cover"
        name="dental_cover"
      >
        <Select placeholder="Select an option" options={dental_cover_options} />
      </Form.Item>
      <FormFooter onFormReset={onFormReset} onFormSubmit={onFormSubmit} />
    </Form>
  </>
};

export default QuoteForm;
