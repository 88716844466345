import './App.css';
import { Route, Routes } from 'react-router-dom';
import FreshPage from './pages/FreshPage';
import 'antd/dist/antd.css';
import LandingPage from './pages/Landing';
import RenewalPage from './pages/RenewalPage';
import Login from './pages/Login';
import { Suspense, useEffect, useState } from 'react';
import { Spin } from 'antd';
import DigitPOC from './pages/DigitPOC';

export const Loader = <div className="loader-div">
  <div className="loader">
    <Spin />
  </div>
</div>

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null),
    [loading, setLoading] = useState<boolean>(true),
    login = (token?: string) => {
      setIsLoggedIn(true);
      if (token) {
        localStorage.setItem('token', token);
      }
    },
    logout = () => {
      setIsLoggedIn(false)
      localStorage.removeItem('token')
    };

  useEffect(() => {
    if (isLoggedIn !== null) {
      setLoading(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      login();
    } else {
      logout();
    }
  }, [])

  if (loading) {
    return Loader;
  }

  return (
    <Suspense
      fallback={Loader}
    >
      <Routes>
        {!isLoggedIn && <>
          <Route path="/" element={<Login login={login} />} />
          <Route path="*" element={<Login login={login} />} />
        </>
        }
        {isLoggedIn && <>
          <Route path="/" element={<LandingPage logout={logout} />} />
          <Route path="/fresh" element={<FreshPage logout={logout} />} />
          <Route path="/renewal" element={<RenewalPage logout={logout} />} />
          <Route path="/poc-digit" element={<DigitPOC logout={logout} />} />
          <Route path="*" element={<LandingPage logout={logout} />} />
        </>
        }
      </Routes>
    </Suspense>
  );
}

export default App;
