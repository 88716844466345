import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface Props {
  logout: () => void;
}

const Logout: React.FC<Props> = ({ logout }) => {
  return <div className="logout" onClick={(e)=>{e.preventDefault(); logout()}}>
    <LogoutOutlined />
  </div>
};

export default Logout;
