import { Col, Row } from "antd";
import Logout from "../components/Logout";
import DigitForm from "../components/DigitForm";
import DigitQuote from "../components/DigitQuote";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";

interface Props {
  logout: VoidFunction;
}

const DigitPOC: React.FC<Props> = ({ logout }) => {
  const [form] = useForm(),
    [quoteDetails, setQuoteDetails] = useState<any>(null),
    onFormReset = () => {
      form.resetFields();
    },
    onFormSubmit = () => {
      form
        .validateFields()
        .then((val) => {
          if (val?.gstNumber || val?.panNumber) {
            if (!val?.quotePremiumEntity || val?.quotePremiumEntity?.length<=0){
              alert('Please add details for necessary age groups')
              return;
            }
            axios
              .post(
                "https://stagingapi.healthysure.co/utils/execute-api-wo-auth",
                {
                  url: "https://preprod-oneapi.godigit.com/OneAPI/digit/generateAuthKey",
                  payload: {
                    username: "35327650",
                    password: "Digit@123$",
                  },
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
              .then((res) => {
                axios
                  .post(
                    "https://stagingapi.healthysure.co/utils/execute-api-wo-auth",
                    {
                      "url": "https://preprod-oneapi.godigit.com/OneAPI/v1/executor",
                      "headers": {
                          "integrationId": "3130-0100",
                          "Authorization": `Bearer ${res?.data?.access_token}`,
                          "Cookie": "AWSALB=XNvM9Lq5d2VSQRI1SeThvsUQVwmEcSWEQyJpBBLoBLnzM8tCV0wi0NlU/2Ny1iEJhaCRFR88Gd3LbLjxtXq/iYmVzZAumm7pdvmfwPwEMIlqYMv0dSAFiQ3N9MJ7; AWSALBCORS=XNvM9Lq5d2VSQRI1SeThvsUQVwmEcSWEQyJpBBLoBLnzM8tCV0wi0NlU/2Ny1iEJhaCRFR88Gd3LbLjxtXq/iYmVzZAumm7pdvmfwPwEMIlqYMv0dSAFiQ3N9MJ7",
                          "Content-Type": "application/json"
                      },
                      "payload": {
                          "gMCSMEMemberAddition(QuickQuote)": {
                              "gstNumber": val?.gstNumber,
                              "totalNoOfLives": `${val?.quotePremiumEntity.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.numberOfEmployee, 0)}`,
                              "endorsementQuoteNumber": "",
                              "endorsementType": "Addition Of Member",
                              "headerParam": {
                                  "Authorization": "6EBSJM99RGVNIC03Y9BMWQ8XVL150CH6"
                              },
                              "masterPolicyNumber": val?.masterPolicyNumber,
                              "endorsementDate": "",
                              "quickQuoteDetails": {
                                quotePremiumEntity: val?.quotePremiumEntity?.map((item: any)=>{
                                  return {
                                    ...item,
                                    ageRange: `${item?.ageRange}`,
                                    sumInsured: `${item?.sumInsured}`,
                                    asAtDate: moment().format('YYYY-MM-DD')
                                  }
                                })
                              },
                              "panNumber": ""
                          }
                      }
                  }

                  )
                  .then((res) => res.data)
                  .then((res) =>
                    setQuoteDetails(
                      res?.data?.["GMC SME-Member Addition (Quick Quote)"]
                        ?.resposeBody
                    )
                  );
              });
          } else {
            alert('Please enter GST number or PAN number')
          }
        })
        .catch((err) => console.log(err));
    };

  return (
    <div className="layout">
      <div className="container">
        <Logout logout={logout} />
        <Row justify={"center"} style={{ padding: "24px 0 24px 0" }}>
          <h1>Get Quick Quote from Digit Insurance</h1>
        </Row>
        <Row gutter={64} align="top">
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <DigitForm
              form={form}
              onFormSubmit={onFormSubmit}
              onFormReset={onFormReset}
            />
          </Col>
          {quoteDetails && (
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <DigitQuote quote={quoteDetails} />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default DigitPOC;
