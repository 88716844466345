import { Col, Row } from "antd";

interface DigitQuoteProps {
  quote: any;
}

const DigitQuote: React.FC<DigitQuoteProps> = ({ quote }) => {
  return (
    <>
      <Row gutter={[24, 48]} align={"top"}>
        <Col span={12}>
          <div className="category-heading">
            <h3>Quote Number</h3>
          </div>
          <div style={{ fontSize: "20px" }}>{quote?.quoteNo}</div>
        </Col>
        <Col span={12}>
          <div className="category-heading">
            <h3>Number of Lives</h3>
          </div>
          <div style={{ fontSize: "20px" }}>{quote?.noOfLives}</div>
        </Col>
        <Col span={12}>
          <div className="category-heading">
            <h3>New Premium</h3>
          </div>
          <div style={{ fontSize: "20px" }}>
            {`₹${(quote?.netPremium)?.toLocaleString("en-IN")}`}
          </div>
        </Col>
        <Col span={12}>
          <div className="category-heading">
            <h3>Gross Premium</h3>
          </div>
          <div style={{ fontSize: "20px" }}>
            {`₹${(quote?.grossPremium)?.toLocaleString("en-IN")}`}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DigitQuote;
