import { Col, Row, Table } from "antd";

interface QuoteDisplayProps {
  quote: Array<any>;
  ghi_quote: Array<any>;
  addOn: any;
}

const QuoteDisplay: React.FC<QuoteDisplayProps> = ({ quote, ghi_quote, addOn }) => {
  const columns = [
    {
      title: "Sum insured",
      dataIndex: "ghi_si",
      key: "ghi_si",
    },
    {
      title: "E",
      dataIndex: "e",
      key: "e",
      render: (val: any) => val ?? "On request"
    },
    {
      title: "ESC",
      dataIndex: "esc",
      key: "esc",
      render: (val: any) => val ?? "On request"
    },
    {
      title: "ESCP",
      dataIndex: "escp",
      key: "escp",
      render: (val: any) => val ?? "On request"
    }
  ], reformatData = (data: any) => {
    return data.map((item: any, index: number) => {
      return {
        id: index,
        ghi_si: `₹${item.ghi_si.toLocaleString('en-IN')}`,
        e: item.e ? `₹${item.e.toLocaleString('en-IN')}` : "On request",
        esc: item.esc ? `₹${item.esc.toLocaleString('en-IN')}` : "On request",
        escp: item.escp ? `₹${item.escp.toLocaleString('en-IN')}` : "On request",
      }
    })
  };

  return <>
    <h3 style={{ fontStyle: "italic", marginBottom: "24px" }}>Annual Breakdown! (Per Employee)</h3>
    <Row gutter={[16, 24]} style={{ marginBottom: "48px" }} align={"top"}>
      <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
        <b>Group Health Insurance:</b>
      </Col>
      <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
        <Table size="small" columns={columns} dataSource={reformatData(ghi_quote)} pagination={false} rowKey="id" />
      </Col>
      {addOn?.gpa && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Group Personal Accident:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.gpa}
        </Col></>}
      {addOn?.gtli && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Group Term Life:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.gtli}
        </Col></>}
      {addOn?.doctor_teleconsultations && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Doctor Teleconsultations:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.doctor_teleconsultations}
        </Col></>}
      {addOn?.health_checkups && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Health Check ups:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.health_checkups}
        </Col></>}
      {addOn?.gym_membership && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Gym Membership:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.gym_membership}
        </Col></>}
      {addOn?.emotional_wellness && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Emotional Wellness:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.emotional_wellness}
        </Col></>}
      {addOn?.dental_cover && <><Col xxl={8} xl={8} lg={8} md={8} sm={16} xs={16}>
        <b>Dental cover:</b>
      </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
          ₹{addOn?.dental_cover}
        </Col></>}
    </Row>
    <div style={{ textAlign: "center" }}>
      <h2 style={{ marginBottom: "4px" }}>Annual <span style={{ color: "red" }}>Indicative</span> Premium</h2>
      <h3>(Excl. GST)</h3>
    </div>

    <Table size="middle" columns={columns} dataSource={reformatData(quote)} pagination={false} rowKey="id" />
  </>;
};

export default QuoteDisplay;
