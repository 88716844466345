interface CardButtonProps {
  onClick: () => void;
  color: string;
  height: "50vh" | "100vh";
}

const CardButton: React.FC<CardButtonProps> = ({onClick, children, color, height}) => {
  return <div className="card-button" onClick={onClick} style={{backgroundColor: color, height: height}}>
    <div className="content">
    {children}
    </div>
  </div>
};

export default CardButton;
