export const ghi_quotes = [
  {
    id: 1,
    ghi_si: "2L",
    e: 2600,
    esc: 4000,
    escp: 15000
  },
  {
    id: 2,
    ghi_si: "3L",
    e: 2900,
    esc: 4800,
    escp: 20000
  },
  {
    id: 3,
    ghi_si: "5L",
    e: 3400,
    esc: 6000,
    escp: 25000
  },
  {
    id: 4,
    ghi_si: "10L",
    e: 4400,
    esc: 7500,
    escp: 40000
  }
];

export const ghi_quotes_under_25 = [
  {
    id: 1,
    ghi_si: "2L",
    e: 4142.805,
    esc: 8187.173,
    escp: 0
  },
  {
    id: 2,
    ghi_si: "3L",
    e: 4819.307,
    esc: 9855.299,
    escp: 0
  },
  {
    id: 3,
    ghi_si: "5L",
    e: 6082.254,
    esc: 12975.916,
    escp: 0
  },
  {
    id: 4,
    ghi_si: "10L",
    e: 0,
    esc: 0,
    escp: 0
  }
];

export const gpa_quotes = [
  {
    id: 1,
    gpa_si: "5L",
    premium: 100
  },
  {
    id: 2,
    gpa_si: "10L",
    premium: 200
  },
  {
    id: 3,
    gpa_si: "20L",
    premium: 400
  },
]

export const gtli_quotes = [
  {
    id: 1,
    gtli_si: "5L",
    premium: 750
  },
  {
    id: 2,
    gtli_si: "10L",
    premium: 1500
  },
  {
    id: 3,
    gtli_si: "20L",
    premium: 3000
  },
]

export const hnw_quotes = {
  doctor_teleconsultation: [
    {
      id: 1,
      premium: 599,
      plan: "e1"
    },
    {
      id: 2,
      premium: 799,
      plan: "e2"
    }
  ],
  health_checkups: 199,
  gym_membership: 1499,
  emotional_wellness: 499,
  dental_cover: [
    {
      id: 1,
      premium: 299,
      plan: "3k"
    },
    {
      id: 2,
      premium: 499,
      plan: "5k"
    }
  ],
};

export const base_city_discount = [
  {
    id: 1,
    tier: "tier1",
    discount: 0
  },
  {
    id: 2,
    tier: "tier2",
    discount: 0.1
  },
  {
    id: 3,
    tier: "tier3",
    discount: 0.2
  },
]

export const without_maternity = {
  e: -600,
  esc: -1400,
  escp: -2000,
};

export const without_maternity_under_25 = {
  e: -1751.315,
  esc: -2238.624,
  escp: 0,
};

export const base_type_discount = [
  {
    id: 1,
    plan_type: "silver",
    discount: 0.1
  },
  {
    id: 2,
    plan_type: "gold",
    discount: 0.05
  },
  {
    id: 3,
    plan_type: "platinum",
    discount: 0
  },
];

export const city_tier_relationship = [
  {
    cities: ["delhi", "mumbai"],
    tier: "tier1"
  },
  {
    cities: ["ahmedabad", "bangalore", "chennai", "hyderabad", "kolkata", "pune", "secunderabad", "surat", "vadodara"],
    tier: "tier2"
  },
  {
    cities: ["rest_of_india"],
    tier: "tier3"
  },
]

export const costs = {
  buffer: 0.05,
  inflation: 0.05,
  management: 0.15,
}

export const ibnr_percentage = 0.04;